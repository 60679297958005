<template>
    <div class="container">
        <login-modal v-if="openModal" @cancel="openModal = false"></login-modal>
        <div class="wrap">
            <div class="header">
                <h2>{{ detailData.resName }}</h2>
                <aside v-if="detailData.id" @click="handleCollectBtn(detailData)">
                    <img v-if="starInit(detailData.id)" :src="require('@/assets/index/collected.png')" alt />
                    <img v-else :src="require('@/assets/index/collect.png')" alt />
                </aside>
                <p class="btn-errorCorrection" @click="onClickErrorCorrection">
                    <img :src="require('@/assets/communication/纠错.png')" />
                    <span>纠错</span>
                </p>
            </div>
            <div class="info-list">
                <section class="info-item">
                    数源单位
                    <span>{{ detailData.idPocName }}</span>
                </section>
                <section class="info-item">
                    数据领域
                    <span>{{ getDomainName(detailData.datadomain, dataDomainOptions) || detailData.datadomain }}</span>
                </section>
                <section class="info-item">
                    上线日期
                    <span>{{ formatTime(detailData.releaseDate) }}</span>
                </section>
                <section class="info-item">
                    更新日期
                    <span>{{ formatTime(detailData.updateDate) }}</span>
                </section>
                <section class="info-item">
                    接口摘要
                    <span>{{ detailData.resSummary }}</span>
                </section>
            </div>
            <div style="margin-top:24px">
                <p class="wrap-title">数据集信息</p>
                <div class="right-item">
                    <span v-if="isApplied">已申请</span>
                    <span v-if="!isApplied" @click="InterfaceApply">接口申请</span>
                    <span @click="InterfaceDetail">接口详情</span>
                    <span @click="visualizetionShow">数据可视化</span>
                </div>
                <div class="interface-table">
                    <table>
                        <tr>
                            <td class="table-title">资源代码</td>
                            <td class="table-content" colspan="5">
                                <div>{{ detailData.resCode }}</div>
                            </td>
                            <td class="table-title">资源提供方</td>
                            <td class="table-content" colspan="5">
                                <div>{{ detailData.idPocName }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-title">所属系统名称</td>
                            <td class="table-content" colspan="2">
                                <div>{{ detailData.sysNames }}</div>
                            </td>
                            <td class="table-title">资源格式</td>
                            <td class="table-content" colspan="2">
                                <div>{{ getLable(detailData.resFormat, labelOptions.resFormatOptions) }}</div>
                            </td>
                            <td class="table-title">数据接口</td>
                            <td class="table-content" colspan="2">
                                <span>接口详情</span>
                            </td>
                            <td class="table-title">资源分类</td>
                            <td class="table-content" colspan="2">
                                <div>无</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-title">下载次数</td>
                            <td class="table-content" colspan="2">
                                <div>{{ detailData.downloadNum }}</div>
                            </td>
                            <td class="table-title">数据领域</td>
                            <td class="table-content" colspan="2">
                                <div>{{ getDomainName(detailData.datadomain, dataDomainOptions) || detailData.datadomain }}</div>
                            </td>
                            <td class="table-title">访问次数</td>
                            <td class="table-content" colspan="2">
                                <div>{{ detailData.visitNum }}</div>
                            </td>
                            <td class="table-title">更新周期</td>
                            <td class="table-content" colspan="2">
                                <div>{{ getLable(detailData.frequery, labelOptions.frequeryOptions) }}</div>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-title">数据下载</td>
                            <td class="table-content" colspan="11">
                                <div v-if="detailData.isopen === '1'">
                                    <span class="load-tag" v-for="(item, index) in loadList" :key="index" @click="loadHandle(index, item)">{{ item }}</span>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <!-- 表格 -->
        <div class="wrap">
            <div class="content">
                <div>
                    <div class="data-table">
                        <a-table
                            :rowKey="
                                (record, index) => {
                                    return index
                                }
                            "
                            :columns="columns"
                            :pagination="false"
                            :data-source="data"
                            :scroll="{ x: 1500 }"
                        >
                            <span slot="dataType" slot-scope="value">{{ getLable(value, labelOptions.dataTypeOptions) }}</span>
                            <span slot="key" slot-scope="value">{{ value === '1' ? '是' : '否' }}</span>
                            <span slot="blank" slot-scope="value">{{ value === '1' ? '是' : '否' }}</span>
                            <span slot="dic" slot-scope="value">{{ value === '1' ? '是' : '否' }}</span>
                            <span slot="exchangeAttr" slot-scope="value">{{ getLable(value, labelOptions.exchangeAttrOptions) }}</span>
                            <span slot="open" slot-scope="value">{{ getLable(value, labelOptions.isOptions) }}</span>
                        </a-table>
                    </div>
                    <div class="pagination">
                        <a-pagination :pageSizeOptions="['5', '10', '15']" :current="current" :defaultPageSize="pageSize" show-size-changer show-quick-jumper :total="dataList.length" :show-total="total => `共${dataList.length} 条数据`" @showSizeChange="handleSizeChange" @change="handlePageChange" />
                    </div>
                </div>
                <div class="tab-bar">
                    <div class="blue-tip">
                        <p>数据项</p>
                    </div>
                    <!-- <div class="orange-tip">
                        <p>关联信息</p>
                    </div>-->
                </div>
            </div>
        </div>
        <error-correction-dialog v-if="errorCorrectionDialogVisible" :data="errorCorrectionData" @submit="onConfirmErrorCorrection" @cancel="onCancelErrorCorrection"></error-correction-dialog>
    </div>
</template>

<script>
import { GetDetail, GetDicts, DeleteStar, GiveStar, GetStarList, GetDataDomainDetail, GetResClassDetail, GetApplicationState, getRelateAppList } from '../api'
import { getLoadTable } from '../api/downLoad'
import { formatTime, getLable, convertRes2Blob, getDeepLabel } from '@/utils/tools'
import { mapState } from 'vuex'
import { DataSetDetailList } from '../dict'
import { getCookie } from '@/utils/cookie'
import LoginModal from '@/components/LoginModal'
import moment from 'moment'
import ErrorCorrectionDialog from '@/views/InteractiveCommunication/components/ErrorCorrectionDialog'
export default {
    props: {
        detailId: String
    },
    data() {
        return {
            relateAppList: [], //我的关联应用列表
            visId: '',
            id: '',
            apiId: '',
            detailData: {},
            columns: [],
            openModal: false,
            detailVisible: false,
            isApplicationData: '', //数据目录是否申请过
            isApplied: '', // 是否申请过
            isOpen: '', //是否受限
            ModalText: '此接口无详情内容',
            labelOptions: {
                resFormatOptions: [],
                frequeryOptions: [],
                dataTypeOptions: [],
                exchangeAttrOptions: [],
                shareAttrOptions: [],
                isOptions: [
                    { label: '否', value: '0' },
                    { label: '是', value: '1' }
                ]
            },
            loadList: ['XLS', 'CSV', 'XML', 'JSON', 'RDF'],
            pageNo: 1,
            pageSize: 5,
            data: [],
            dataList: [],
            starList: [],
            current: 1,
            // 用户数据
            KF_USER: getCookie('KF_USER') ? JSON.parse(getCookie('KF_USER')) : {},
            // 资源分类和数据领域名称
            resClassName: '',
            dataDomainName: '',
            meta: [
                { name: 'ArticleTitle', content: '数据集详情' },
                { name: 'PubDate', content: moment().format('YYYY-MM-DD') },
                { name: 'ContentSource', content: '舟山市数据开放' }
            ],
            errorCorrectionDialogVisible:false,
            errorCorrectionData:{}
        }
    },
    components: {
        LoginModal,
        ErrorCorrectionDialog
    },
    created() {
        this.visId = this.detailId != undefined && this.detailId != '' ? this.detailId : this.$route.query.id
        this.$store.dispatch('getDataDomain')
        this.setMenu()
        this.getData()
        this.getJson()
        this.getStar()
        this.getRelateAppList()
        this.initState()
    },
    mounted() {
        this.visId = this.detailId != undefined && this.detailId != '' ? this.detailId : this.$route.query.id
        console.log(this.visId)
        Object.keys(DataSetDetailList).forEach(item => {
            this.columns.push({
                title: DataSetDetailList[item],
                dataIndex: item,
                key: item,
                width: 200,
                fixed: item == 'name' ? 'left' : '',
                scopedSlots: { customRender: item }
            })
        })
        // this.getJson()
        // this.getStar()
        // // this.getData()
        // this.initState()
    },
    computed: {
        ...mapState(['dataDomainOptions'])
    },
    methods: {
        setMenu() {
            for (let item of this.meta) {
                let flag = false
                for (let meta of document.querySelectorAll('meta')) {
                    if (meta.hasAttribute('name') && meta.getAttribute('name') === item.name) {
                        meta.setAttribute('content', item.content)
                        flag = true
                        break
                    }
                }
                if (!flag) {
                    let el = document.createElement('meta')
                    el.setAttribute('name', item.name)
                    el.setAttribute('content', item.content)
                    let firstLink = document.querySelectorAll('head>link')[0]
                    document.getElementsByTagName('head')[0].insertBefore(el, firstLink)
                }
            }
        },
        formatTime,
        getLable,
        getRelateAppList() {
            let params = {
                param: {
                    applicationState: 3
                }
            }
            getRelateAppList(params)
                .then(res => {
                    // console.log('getRelateAppList=>', res)
                    this.relateAppList = res.records || []
                })
                .catch(err => {
                    console.log(err)
                })
        },
        getDomainName(el, list) {
            // debugger
            if (!el) {
                return el
            }
            let elList = el.split(',')
            let arr = elList.map(item => {
                return getDeepLabel(item, list)
            })
            return arr.join(',')
        },

        handleCollectBtn(value) {
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            //取消收藏操作
            let res = false
            for (let item of this.starList) {
                if (value.id == item) {
                    res = true
                    DeleteStar({ id: value.id, resourceType: 1 }).then(() => {
                        this.getStar()
                        return
                    })
                } else {
                    res = false
                }
            }
            if (!res) {
                //收藏
                GiveStar({
                    publishTime: value.releaseDate ? formatTime(value.releaseDate) + ' ' + formatTime(value.releaseDate, true) : '',
                    resourceAbstract: value.resSummary,
                    resourceId: value.id,
                    resourceName: value.resName,
                    resourceProviderId: value.idPoc,
                    resourceProviderName: value.idPocName,
                    resourceType: 1,
                    resourceUpdateTime: value.updateDate ? formatTime(value.updateDate) + ' ' + formatTime(value.updateDate, true) : ''
                }).then(() => {
                    this.getStar()
                    return
                })
            }
        },
        async getStar() {
            await GetStarList({ id: this.KF_USER.id, isstructuredData: 1 }).then(res => {
                this.starList = res || []
            })
        },
        starInit(id) {
            for (var item of this.starList) {
                if (item == id) {
                    return true
                }
            }
            return false
        },
        // 页码改变事件
        handlePageChange(index) {
            this.current = index
            this.pageNo = index
            this.data = this.dataList.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
            // this.getData()
        },
        // 每页展示条数改变事件
        handleSizeChange(index, size) {
            console.log(index, size)
            this.pageNo = 1
            this.current = 1
            this.pageSize = size
            this.data = this.dataList.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)

            // this.pageSize = size
            // this.getData()
        },
        getData() {
            let id
            void (this.detailId != '' && this.detailId != undefined ? ({ id } = { id: this.detailId }) : ({ id } = this.$route.query))
            GetDetail({ id, isStructuredData: 1 }).then(res => {
                console.log(res)
                this.apiId = res.apiId
                this.detailData = res
                console.log(res)
                let data = eval(res.collectionStructure)
                //查看共享属性是否为数组
                if (Array.isArray(data)) {
                    this.dataList = data
                    this.data = this.dataList.slice((this.pageNo - 1) * this.pageSize, this.pageNo * this.pageSize)
                }
            })
        },
        //查询接口状态
        initState() {
            const res = GetApplicationState({
                catalogId: this.visId
            }).then(res => {
                if (res) {
                    this.isApplicationData = res.catalogHasApplied //	数据目录是否申请过
                    this.isApplied = res.hasApplied //是否申请过
                    this.isOpen = res.open //是否受限
                }
            })
        },

        async getJson() {
            // 获取字典项
            await GetDicts({ types: 'format,frequery,dataType,exchangeAttr,shareAttr' }).then(res => {
                this.labelOptions.frequeryOptions = res.frequery
                this.labelOptions.resFormatOptions = res.format
                this.labelOptions.dataTypeOptions = res.dataType
                this.labelOptions.exchangeAttrOptions = res.exchangeAttr
                this.labelOptions.shareAttrOptions = res.shareAttr
            })
            // if (this.orgOptions.length == 0) {
            //     await this.$store.dispatch('getData')
            // }
        },
        // 跳转接口数据可视化
        visualizetionShow() {
            if (!this.detailData.dataBaseName) {
                this.$message.warn('暂无数据，无法可视化')
                return
            }
            this.$router.push({
                name: 'DataVisualization',
                query: {
                    id: this.visId
                }
            })
        },

        // 跳转接口详情接口
        InterfaceDetail() {
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (this.apiId == null) {
                const h = this.$createElement
                this.$info({
                    title: '提示',
                    content: h('div', {}, [h('p', '此接口无详情内容')]),
                    onOk() {}
                })
                return
            }
            this.$router.push({
                name: 'InterfaceServiceDataSetDetail',
                query: {
                    id: this.apiId
                }
            })
        },
        showGoAddAppConfirm() {
            let _this = this
            this.$confirm({
                class: 'goAddAppConfirm',
                title: '您当前没有审核通过的应用，不可进行接口申请。',
                closable: true,
                cancelText: '确定',
                cancelType: 'primary',
                okText: '前往应用注册',
                okType: 'link',
                maskClosable: true,
                onOk() {
                    _this.$router.push({
                        name: 'MyAppList'
                    })
                },
                onCancel() {
                    console.log('Cancel')
                }
            })
        },
        // 接口申请
        InterfaceApply() {
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (!this.relateAppList.length) {
                //已登录，未申请接口，但是该账号未关联应用，弹出提示去关联应用提示框随后跳到新增关联应用页面
                this.showGoAddAppConfirm()
                return
            }
            if (this.apiId == null) {
                const h = this.$createElement
                this.$info({
                    title: '提示',
                    content: h('div', {}, [h('p', '此接口无法申请')]),
                    onOk() {}
                })
            } else if (!this.isOpen) {
                // 受限目录已申请 可以跳转到详情
                if (this.isApplicationData) {
                    this.$router.push({
                        name: 'InterfaceServiceApplication',
                        params: {
                            id: this.apiId
                        }
                    })
                    return
                }
                // 受限
                const h = this.$createElement
                this.$info({
                    title: '提示',
                    content: h('div', {}, [h('p', '此接口需要先申请数据目录')]),
                    onOk() {}
                })
            } else if (this.isOpen) {
                this.$router.push({
                    name: 'InterfaceServiceApplication',
                    params: {
                        id: this.apiId
                    }
                })
            }
        },
        //下载
        loadHandle(index, item) {
            if (!getCookie('kf_token')) {
                this.openModal = true
                return
            }
            if (!this.detailData.dataBaseName) {
                this.$message.warn('暂无数据，无法下载')
                return
            }
            getLoadTable({
                id: this.visId,
                type: index + 1
            }).then(response => {
                // debugger
                console.log(response)
                convertRes2Blob(response)
            })
        },
        onClickErrorCorrection(){
            if (!getCookie('KF_USER')) {
                this.openModal = true
                return
            }
            console.log('dataList=>',this.dataList);
            this.errorCorrectionData = {
                type:1,
                resId:this.detailData.id,
                resName:this.detailData.resName,
                dataBaseName:this.detailData.dataBaseName,
                dataItems:this.dataList.map(item=>({
                    label:item.nameCn ?? item.nameEn,
                    value:item.nameCn ?? item.nameEn,
                }))
            }
            this.errorCorrectionDialogVisible = true
        },
        onConfirmErrorCorrection(){
            this.errorCorrectionDialogVisible = false
        },
        onCancelErrorCorrection(){
            this.errorCorrectionDialogVisible = false
        },
    }
}
</script>

<style scoped lang="scss">
.container {
    // width: 1200px;
    max-width: 1200px;
    min-width: 496px;
    margin: 0 auto;
    padding: 36px 0 16px 0;
    .wrap {
        position: relative;
        background-color: #fff;
        border: 1px solid #dbdbdb;
        padding: 12px 20px 32px;
        margin-bottom: 20px;
        .header {
            display: flex;
            align-items: center;
            h2 {
                font-size: 22px;
                /* font-weight: normal; */
                /* font-stretch: normal; */
                /* line-height: 9px; */
                letter-spacing: 0px;
                color: #303343;
            }
            aside {
                cursor: pointer;
                padding: 0px 30px;
                img {
                    display: block;
                }
            }
            .btn-errorCorrection{
                font-size: 14px;
                color: #ff9600;
                cursor: pointer;
                display: flex;
                align-items: center;
                line-height: 1;
                img{
                    width: 16px;
                    max-height: 100%;
                    margin-right: 4px;
                }
            }
        }
        .info-list {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-top: 18px;
            .info-item {
                position: relative;
                font-size: 14px;
                font-weight: normal;
                font-stretch: normal;
                /* line-height: 9px; */
                letter-spacing: 0px;
                color: #8e92a1;
                padding: 4px 16px;
                &:not(:first-of-type) {
                    &:before {
                        content: '';
                        position: absolute;
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1px;
                        height: 18px;
                        background: #303343;
                    }
                }
                &:first-of-type {
                    padding-left: 0;
                }
                > span {
                    padding-left: 8px;
                    color: #303343;
                }
            }
        }
        .wrap-title {
            font-size: 20px;
            font-weight: normal;
            font-stretch: normal;
            /* line-height: 9px; */
            letter-spacing: 0px;
            color: #303343;
            float: left;
            margin-bottom: 28px;
        }
        .right-item {
            float: right;
            margin-top: 20px;
            span {
                display: inline-block;
                width: 74px;
                height: 28px;
                text-align: center;
                line-height: 28px;
                color: #fff;
                margin-left: 20px;
                background-color: #169bd5;
                border-radius: 5px;
                font-size: 13px;
                cursor: pointer;
            }
        }
        .interface-table {
            width: 100%;
            table {
                width: 100%;
                table-layout: fixed;
                background-color: #ffffff;
                tr {
                    .table-title {
                        text-align: right;
                        color: #8e92a1;
                        background-color: #f9f9f9;
                        width: 100px;
                    }
                    .table-content {
                        word-break: break-word;
                        height: 40px;
                        div {
                            height: auto;
                            height: 40px;
                            line-height: 40px;
                            text-overflow: -o-ellipsis-lastline;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box; /*不能用block等其他*/
                            -webkit-line-clamp: 2; /*IE和火狐不支持*/
                            -webkit-box-orient: vertical;
                            .load-tag {
                                display: inline-block;
                                width: 40px;
                                height: 20px;
                                background-color: #d3f1e2;
                                border-radius: 10px;
                                margin-right: 10px;
                                color: #47c488;
                                text-align: center;
                                line-height: 20px;
                                cursor: pointer;
                            }
                        }
                    }
                    td {
                        font-size: $fs12;
                        padding: 0 12px;
                        border: 1px solid #dbdbdb;
                        color: #000025;
                    }
                }
            }
        }
        .content {
            .tab-bar {
                top: 20px;
                position: absolute;
                left: -44px;
                width: 44px;
                height: 144px;
                .blue-tip {
                    width: 44px;
                    height: 144px;
                    position: absolute;
                    z-index: 5;
                    top: 0;
                    background-color: transparent;
                    box-shadow: 0px 3px 7px 0px rgba(110, 129, 148, 0.16);
                    color: #fff;
                    font-size: 18px;
                    p {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 24px;
                        text-align: center;
                        transform: translate(-50%, -50%);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -1;
                        background: #357df1;
                        transform: perspective(12px) rotateY(-3deg) scale(1.3);
                        transform-origin: right;
                        border-bottom-left-radius: 8px;
                        border-top-left-radius: 8px;
                    }
                }
                .orange-tip {
                    width: 44px;
                    height: 144px;
                    top: 144px;
                    background-color: transparent;
                    box-shadow: 0px 3px 7px 0px rgba(110, 129, 148, 0.16);
                    color: #fff;
                    font-size: 18px;
                    position: absolute;
                    p {
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        width: 24px;
                        text-align: center;
                        transform: translate(-50%, -50%);
                    }
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: -1;
                        background: #f58646;
                        transform: perspective(12px) rotateY(-3deg) scale(1.3);
                        transform-origin: right;
                        border-bottom-left-radius: 8px;
                        border-top-left-radius: 8px;
                    }
                }
            }
            /deep/.data-table {
                min-height: 300px;
                margin-top: 12px;
                /deep/ table {
                    color: #000025;
                    border-collapse: collapse;
                    th,
                    td {
                        height: 40px !important;
                        padding: 0 !important;
                        padding-left: 16px !important;
                    }
                    td {
                        border-left: none;
                        border-right: none;
                    }
                    tr {
                        height: 40px !important;
                    }
                }
                .ant-table-body {
                    &::-webkit-scrollbar {
                        height: 10px;
                    }
                    &::-webkit-scrollbar-thumb {
                        border-radius: 8px;
                        // -webkit-box-shadow: inset 0 0 5px #ffffff;
                        background: #cccccc;
                        // border: solid 1px #d5d6e3;
                    }
                    &::-webkit-scrollbar-track {
                        // -webkit-box-shadow: 0;
                        border-radius: 8px;
                        // border: solid 1px #d5d6e3;
                        background: #f6f6f6;
                    }
                }
            }
            .pagination {
                display: flex;
                justify-content: center;
                margin-top: 12px;
            }
        }
    }
}
</style>

import { baseRequest } from '@/API/load.js'

export function getLoadTable(query) {
    return baseRequest({
        url: '/catalog/dataOpen/dataExport',
        method: 'GET',
        params: query,
        responseType: 'blob'
    })
}

export const DataSetDetailList = {
    nameCn: '字段中文名',
    nameEn: '字段英文名',
    dataType: '数据类型',
    length: '字段长度',
    key: '是否主键',
    blank: '是否为空',
    dic: '是否字典项',
    open: '是否开放',
    openConditions: '开放条件'
}

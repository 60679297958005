<template>
    <div>
        <a-modal width="1000px" title="目录信息" @cancel="handleModalCancel" :visible="visible" :footer="null">
            <a-icon slot="closeIcon" type="close-circle" />
            <div class="info-table">
                <table>
                    <tr>
                        <td class="table-title">目录资源名称</td>
                        <td class="table-content">{{ data.resName }}</td>
                        <td class="table-title">目录资源代码</td>
                        <td class="table-content">{{ data.resCode }}</td>
                    </tr>
                    <tr>
                        <td class="table-title">资源提供方</td>
                        <td class="table-content">{{ data.idPocName }}</td>
                        <td class="table-title">数据领域</td>
                        <td class="table-content">{{ getDomainName(data.datadomain, dataDomainOptions) }}</td>
                    </tr>
                    <tr>
                        <td class="table-title">信息资源格式</td>
                        <td class="table-content">{{ getLable(data.resFormat, labelOptions.resFormatOptions) }}</td>
                        <td class="table-title">发布时间</td>
                        <td class="table-content">{{ formatTime(data.releaseDate) }}</td>
                    </tr>
                    <tr>
                        <td class="table-title">资源摘要</td>
                        <td class="table-content" colspan="3">{{ data.resSummary }}</td>
                    </tr>
                </table>
            </div>
            <div class="modal-table">
                <a-table
                    :rowKey="
                        (record, index) => {
                            return index
                        }
                    "
                    :columns="columns"
                    :pagination="false"
                    :data-source="dataSource"
                    :scroll="{ x: 1500 }"
                    class="tb-scrollbar-x"
                >
                    <span slot="dataType" slot-scope="value">{{ getLable(value, labelOptions.dataTypeOptions) }}</span>
                    <span slot="key" slot-scope="value">{{ getLable(value, labelOptions.iskeyOptions) }}</span>
                    <span slot="blank" slot-scope="value">{{ getLable(value, labelOptions.isBlankOptions) }}</span>
                    <span slot="dic" slot-scope="value">{{ getLable(value, labelOptions.isDicOptions) }}</span>
                    <span slot="exchangeAttr" slot-scope="value">{{ getLable(value, labelOptions.exchangeAttrOptions) }}</span>
                    <span slot="open" slot-scope="value">{{ getLable(value, labelOptions.isOpenOptions) }}</span>
                </a-table>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { formatTime, getLable, getDeepLabel } from '@/utils/tools'
import { mapState } from 'vuex'
import { DataSetDetailList } from '@/views/OpenData/dict'
import { GetDicts, GetResClassDetail } from '@/views/OpenData/api'
export default {
    props: {
        data: {
            type: Object,
            default: () => {
                return {}
            }
        },
        visible: {
            type: Boolean
        }
    },
    computed: {
        // store取出组织机构，数据领域，资源分类数据
        ...mapState(['dataDomainOptions'])
    },
    data() {
        return {
            labelOptions: {
                resFormatOptions: [],
                iskeyOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isBlankOptions: [
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isDicOptions: [
                    {
                        label: '否',
                        value: ''
                    },
                    {
                        label: '是',
                        value: '1'
                    },
                    {
                        label: '否',
                        value: '2'
                    }
                ],
                isOpenOptions: []
            },
            resClassName: '',
            dataDomainName: '',
            // 详情交换数据
            dataSource: [],
            // 交换数据列
            columns: []
        }
    },
    methods: {
        formatTime,
        getLable,
        getDomainName(el, list) {
            if (!el) {
                return el
            }
            let elList = el.split(',')
            let arr = elList.map(item => {
                return getDeepLabel(item, list)
            })
            return arr.join(',')
        },
        async getJson() {
            await GetDicts({ types: 'format,frequery,dataType,exchangeAttr,shareAttr,isOpen' }).then(res => {
                this.labelOptions.isOpenOptions = res.isOpen
                this.labelOptions.dataTypeOptions = res.dataType
                this.labelOptions.exchangeAttrOptions = res.exchangeAttr
                this.labelOptions.resFormatOptions = res.format
            })
        },
        handleModalCancel() {
            this.$emit('modalCancel')
        }
    },
    watch: {
        async visible() {
            if (this.visible) {
                await GetResClassDetail({ id: this.data.resClass }).then(result => {
                    // this.resClassName = result.name
                })
            }
            let data = eval(this.data.collectionStructure)
            //查看共享属性是否为数组
            if (Array.isArray(data)) {
                this.dataSource = data
            }
        }
    },
    mounted() {
        Object.keys(DataSetDetailList).forEach(item => {
            this.columns.push({
                title: DataSetDetailList[item],
                dataIndex: item,
                key: item,
                width: 140,
                fixed: item == 'name' ? 'left' : '',
                scopedSlots: { customRender: item }
            })
        })
        this.$store.dispatch('getDataDomain')
        this.getJson()
    }
}
</script>

<style lang="scss" scoped>
/deep/ .ant-modal-title {
    font-size: 30px;
    height: 50px;
    line-height: 50px;
    color: #000025;
}
/deep/ .ant-modal-close {
    padding: 16px 40px 16px 0;
    line-height: 50px;
    height: 50px;
    color: #dbdbdb;
}
/deep/ .ant-modal-close-x {
    font-size: 20px;
}
.modal-table {
    padding: 0 40px;
    margin-bottom: 32px;
    /deep/ tr {
        td,
        th {
            height: 30px !important;
            padding: 0 !important;
            padding-left: 16px !important;
            background-color: #eef1f8;
            font-size: $fs12;
            color: #000025;
        }
        td {
            background-color: rgba($color: #eef1f8, $alpha: 0.5);
        }
    }

    .action {
        font-size: 16px;
    }
    .button {
        margin-bottom: 18px;
        display: flex;
        justify-content: center;
        margin-top: 80px;
    }
}
.info-table {
    padding: 0 40px 32px 40px;
    table {
        width: 100%;
        font-size: $fs14;
        td {
            height: 40px;
            border: solid 1px #dbdbdb;
        }
        td.table-title {
            background-color: #f9f9f9;
            width: 130px;
            text-align: right;
            padding-right: 12px;
        }
        td.table-content {
            width: 318px;
            padding-left: 16px;
            word-break: break-word;
            span {
                background: #eef1f8;
                width: 100px;
                height: 26px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #999999;
                border-radius: 2px;
            }
            span.active {
                background: #357df1;
                color: #fff;
                cursor: pointer;
            }
        }
    }
}
</style>
